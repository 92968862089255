import React, { useEffect, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { MasonryPhotographersProfile } from '../masonry-photographers-profile/MasonryPhotographersProfile';
import { FriendsAndFamily } from '../friends-and-family/FriendsAndFamily';
import { SERVER_URL, BUCKET_IMAGES } from '../../common/CONFIG';
import './style.scss';

type DataPhotographersType = {
  id: number | string;
  pictureName: string;
  pictureUrl: string;
  photographer: string;
  totalPurchased: string;
  totalPictures: string;
  buy: boolean;
  favorite: boolean;
  download: boolean;
  resolution: string;
  masonryHeight?: string;
  marginBottom?: string;
  refPosition?: string;
  ref?: number | string;
  notes?: string;
  cartAdded?: boolean;
  cartBought?: boolean;
  documentName?: string;
  extension?: string;
  type?: string;
};
type Props = {
  // data: DataPhotographersType[];
  data: any;
  openModal: boolean;
  setOpenModal: any;
  isBrandFav: any;
  setIsBrandFav: any;
  hideExtraButtons: any;
  currentInspirations: any;
  setCurrentInspirations: any;
  setForRefresh: any;
};

export function Modal(props: Props) {
  const {
    openModal,
    setOpenModal,
    data,
    isBrandFav,
    setIsBrandFav,
    hideExtraButtons,
    currentInspirations,
    setCurrentInspirations,
    setForRefresh,
  } = props;

  function closeModal() {
    setOpenModal(false);
  }

  const [specialization, setSpecialization] = useState([]);

  useEffect(() => {
    if (data?.creatorExtraInfo?.specializationCategories) {
      setSpecialization(
        JSON.parse(data?.creatorExtraInfo?.specializationCategories),
      );
    }
  }, [data?.creatorExtraInfo?.specializationCategories]);

  useEffect(() => {
    if (data?.creatorInfo?.specializationCategories) {
      setSpecialization(
        JSON.parse(data?.creatorInfo?.specializationCategories),
      );
    }
  }, [data?.creatorInfo?.specializationCategories]);

  const { user: currentUser } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (data?.isBrandFav) {
      setIsBrandFav(data?.isBrandFav);
    }
  }, [data?.isBrandFav]);

  async function addCreatorToBrandsFav(userCreatorId: any) {
    setIsBrandFav(!isBrandFav);

    const route = `${SERVER_URL}addCreatorToBrandsFav/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        addCreatorToBrandsFav: userCreatorId,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {})
      .catch(() => {});
  }

  async function removeFavCreator(creatorId: number) {
    setIsBrandFav(!isBrandFav);

    const route = `${SERVER_URL}deleteCreatorFromBrandsFav/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        deleteCreatorFromBrandsFav: creatorId,
      })
      .then((res) => {
        if (res.status === 201) {
          //
        }
        return res.data;
      })
      .catch(() => {});
  }

  return (
    <Transition appear show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto overflow-x-hidden"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 -z-[10]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="-z-[10] inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="-z-[10] my-8 inline-block w-full overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
              <section className="m-auto w-full">
                <div className="flex justify-end">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mt-1 mr-2 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                  >
                    <p className="mr-3 self-center text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-2 h-6 w-6 self-center align-middle"
                    />
                  </div>
                </div>

                <div className="mx-auto mt-10 w-11/12">
                  <section className="flex w-full ">
                    <div className="w-full text-gray-500 dark:text-white md:w-[3/4]">
                      <h1 className="text-lg font-bold md:text-3xl">
                        Meet the Photographer
                      </h1>

                      <h1 className="mt-[180px] text-lg font-semibold md:mt-6">
                        {data?.creatorExtraInfo?.nickname}
                        {data?.creatorInfo?.nickname}
                      </h1>

                      <p className="mt-6 text-sm ">
                        {data?.creatorExtraInfo?.bio}
                        {data?.creatorInfo?.bio}
                      </p>
                      <h1 className="mt-4 ">Highlights & Specializations</h1>
                      <ul className="ml-5 ">
                        {specialization?.map((element: any, index: number) => (
                          <li
                            className="list-disc "
                            key={`spec + ${index.toString()}`}
                          >
                            {element}
                          </li>
                        ))}
                      </ul>

                      {hideExtraButtons && (
                        <div className="flex">
                          {isBrandFav ? (
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={() => removeFavCreator(data?.user?.id)}
                              className="z-0  mt-7 rounded-3xl border-2 border-sky-500 bg-sky-500 px-4 py-2 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
                            >
                              Added to Favorites
                            </div>
                          ) : (
                            <div
                              onClick={() =>
                                addCreatorToBrandsFav(data?.user?.id)
                              }
                              role="button"
                              tabIndex={0}
                              className="z-0  mt-7 rounded-3xl border-2 border-sky-500 bg-sky-500 px-4 py-2 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
                            >
                              Add Photographer to Favorites
                            </div>
                          )}

                          {/* <div className="z-0  ml-6 mt-7 rounded-3xl border-2 border-gray-300 px-4 py-2 text-center text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:text-white dark:hover:text-sky-400">
                            Create Brief & Invite Photographer
                          </div> */}
                        </div>
                      )}
                    </div>
                    <div className="absolute top-[10.75rem] w-[80%] md:relative md:top-0 md:w-1/2">
                      {data?.creatorExtraInfo ? (
                        <img
                          src={`${BUCKET_IMAGES}avatar/${data?.user?.username}/${data?.creatorExtraInfo?.avatarUrl}`}
                          alt=""
                          className="absolute left-[105px] z-20 inline-block h-24 w-24 rounded-full  object-cover align-middle md:left-[5.20vw] md:h-[22vw] md:w-[22vw]"
                        />
                      ) : (
                        <img
                          src={`${BUCKET_IMAGES}avatar/${data?.userInfo?.username}/${data?.creatorInfo?.avatarUrl}`}
                          alt=""
                          className="absolute left-[105px] z-20 inline-block h-24 w-24 rounded-full  object-cover align-middle md:left-[5.20vw] md:h-[22vw] md:w-[22vw]"
                        />
                      )}

                      <div className="background-photographer-card-profile z-10 mx-2  h-[100px]  text-center dark:mix-blend-multiply md:mt-10 md:h-[26.68vh] " />
                    </div>
                  </section>
                  <br />
                  <div className="mt-40">
                    <MasonryPhotographersProfile
                      data={data?.creatorPhotos}
                      currentInspirations={currentInspirations}
                      setCurrentInspirations={setCurrentInspirations}
                      setForRefresh={setForRefresh}
                      creatorUsername={
                        data?.userInfo?.username || data?.user?.username
                      }
                    />
                  </div>
                  <FriendsAndFamily
                    creatorUsername={
                      data?.user?.username || data?.userInfo?.username
                    }
                    data={data?.creatorTalents}
                  />
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
