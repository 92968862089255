import React, { useRef, useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { BUCKET_IMAGES } from '../../common/CONFIG';
import './style.scss';

type PropsProfileCreator = {
  creatorUsername: any;
  creatorExtraInfo: any;
  onChangeCreatorProfileExtra: any;
  onChangeAvatar: any;
  activityAccessSelected: any;
  handleOnChangeActivity: any;
  handleOnChangeShotLocation: any;
  shotLocationSelected: any;
  specializationCategoriesSelected: any;
  handleOnChangeSpecializationCategories: any;
  contactViaPhone: any;
  setContactViaPhone: any;
  setFlagPhone: any;
  flagPhone: boolean;
};

const optionsBase: any = [
  { label: '4', value: 4, selected: false },
  { label: '5', value: 5, selected: false },
  { label: '6', value: 6, selected: false },
  { label: '7', value: 7, selected: false },
  { label: '8', value: 8, selected: false },
  { label: '9', value: 9, selected: false },
  { label: '10', value: 10, selected: false },
  { label: '11', value: 11, selected: false },
  { label: '12', value: 12, selected: false },
  { label: '13', value: 13, selected: false },
  { label: '14', value: 14, selected: false },
  { label: '15', value: 15, selected: false },
];

export function ProfileDetails(props: PropsProfileCreator) {
  const {
    creatorUsername,
    creatorExtraInfo,
    onChangeCreatorProfileExtra,
    onChangeAvatar,
    activityAccessSelected,
    handleOnChangeActivity,
    handleOnChangeShotLocation,
    shotLocationSelected,
    specializationCategoriesSelected,
    handleOnChangeSpecializationCategories,
    contactViaPhone,
    setContactViaPhone,
    setFlagPhone,
    flagPhone,
  } = props;

  const handleContactViaPhone = () => {
    setContactViaPhone((value: boolean) => !value);
    setFlagPhone(true);
  };

  useEffect(() => {
    if (flagPhone) {
      onChangeCreatorProfileExtra('contactViaPhone', contactViaPhone);
    }
  }, [contactViaPhone]);

  const fileUpload = useRef<HTMLInputElement>(null);
  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };
  const focusNameInput = () => {
    nameInput!.current!.focus();
  };
  const focusAboutInput = () => {
    aboutInput!.current!.focus();
  };
  const focusPhoneInput = () => {
    phoneInput!.current!.focus();
  };
  const nameInput = useRef<HTMLInputElement>(null);
  const aboutInput = useRef<HTMLTextAreaElement>(null);
  const phoneInput = useRef<HTMLInputElement>(null);
  const [dropMin, setDropMin] = useState(false);
  const [options, setOptions] = useState(optionsBase);
  const [optionMinSelected, setOptionMinSelected] = useState();

  useEffect(() => {
    setOptions(optionsBase);
  }, []);

  useEffect(() => {
    if (creatorExtraInfo?.minPhotosRequest) {
      const index = optionsBase.findIndex(
        (elem: { label: any }) =>
          elem.label === creatorExtraInfo.minPhotosRequest.toString(),
      );
      optionsBase.forEach((element: { selected: boolean }) => {
        // eslint-disable-next-line no-param-reassign
        element.selected = false;
      });

      optionsBase[index].selected = true;
      setOptions(optionsBase);
      setOptionMinSelected(creatorExtraInfo?.minPhotosRequest);
    }
  }, [creatorExtraInfo]);

  function updateMinPhotos(value: any) {
    onChangeCreatorProfileExtra('minPhotosRequest', value);

    const index = optionsBase.findIndex(
      (elem: { value: any }) => elem.value === value,
    );

    optionsBase.forEach((element: { selected: boolean }) => {
      // eslint-disable-next-line no-param-reassign
      element.selected = false;
    });

    optionsBase[index].selected = true;
    setOptions(optionsBase);
    setOptionMinSelected(creatorExtraInfo?.minPhotosRequest);
    setDropMin(false);
  }

  return (
    <section className="bg-gray-100 text-gray-500 dark:bg-slate-900 dark:text-white">
      <div className="mx-auto w-10/12 pt-10">
        <h1 className="text-2xl font-semibold text-gray-600 dark:text-white ">
          Profile
        </h1>

        <section className="mx-auto flex w-full flex-col-reverse md:flex-row">
          <div className="grid w-9/12 ">
            <div className="mt-5 flex">
              Name
              <img
                onClick={focusNameInput}
                onKeyDown={focusNameInput}
                src="./img/write.png"
                alt=""
                className="ml-3 h-5 w-6 place-self-center hover:cursor-pointer hover:opacity-80"
              />
            </div>
            <input
              className="my-1  mr-2 block h-12  w-80 bg-gray-100 py-2 text-3xl font-bold text-gray-600 placeholder:text-gray-600 hover:cursor-pointer focus:outline-none dark:bg-slate-900 dark:text-white  dark:placeholder:text-white md:w-96"
              placeholder="Enter Name"
              type="text"
              name="profileName"
              maxLength={50}
              ref={nameInput}
              value={creatorExtraInfo?.nickname}
              onChange={(e) =>
                onChangeCreatorProfileExtra('nickname', e.target.value)
              }
            />

            <div className="mt-5 flex">
              About
              <img
                onClick={focusAboutInput}
                src="./img/write.png"
                alt=""
                className="ml-3 h-5 w-6 place-self-center hover:cursor-pointer hover:opacity-80"
              />
            </div>

            <textarea
              className="md:w-full w-[300px] mt-1  min-h-[200px] resize-none overflow-y-auto bg-gray-100 py-3 hover:cursor-pointer focus:outline-none dark:bg-slate-900 dark:placeholder:text-white"
              placeholder=""
              ref={aboutInput}
              value={creatorExtraInfo?.bio}
              onChange={(e) =>
                onChangeCreatorProfileExtra('bio', e.target.value)
              }
              maxLength={2000}
            />

            <section className="mt-10 md:inline-flex grid md:gap-0 gap-5 md:pr-0 pr-10">
              <div className="inline-flex items-center place-self-center">
                <p>Allow Brand Contact</p>
                <Switch
                  checked={contactViaPhone}
                  onChange={handleContactViaPhone}
                  className={`${contactViaPhone ? 'bg-sky-300' : 'bg-gray-300'}
                                duration-400 relative mx-5
                                inline-flex h-[1.8125rem] w-[4.0625rem]
                                shrink-0 cursor-pointer
                                place-self-center rounded-full
                                border-2 border-transparent transition-colors
                                ease-in-out focus:outline-none focus-visible:ring-2
                                focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span
                    aria-hidden="true"
                    className={`${
                      contactViaPhone ? 'translate-x-9' : 'translate-x-0'
                    }
                                duration-400 pointer-events-none inline-block h-[1.5625rem]
                                w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out
                                dark:bg-slate-800`}
                  />
                </Switch>
                {contactViaPhone ? (
                  <p className="text-sky-300 dark:text-white">Yes</p>
                ) : (
                  <p className="text-gray-300 dark:text-white">Yes</p>
                )}
              </div>

              {contactViaPhone && (
                <div className="md:ml-5 flex place-self-center">
                  <p className="place-self-center font-semibold text-gray-600 dark:text-white">
                    Phone:
                  </p>

                  <div className="flex w-fit place-self-center ">
                    <input
                      className={`w-34 ml-3  block h-5 bg-gray-100 text-gray-600 placeholder:text-gray-600 hover:cursor-pointer focus:outline-none dark:bg-slate-900 dark:text-white dark:placeholder:text-white `}
                      placeholder="Phone number"
                      type="text"
                      name="porjectName"
                      maxLength={50}
                      ref={phoneInput}
                      value={creatorExtraInfo?.phone}
                      onChange={(e) =>
                        onChangeCreatorProfileExtra('phone', e.target.value)
                      }
                    />
                    <img
                      onClick={focusPhoneInput}
                      onKeyDown={focusPhoneInput}
                      src="./img/write.png"
                      alt=""
                      className="ml-3 h-5 w-6 place-self-center hover:cursor-pointer hover:opacity-80"
                    />
                  </div>
                </div>
              )}
            </section>

            <br />

            <div className="mt-10 grid md:mt-3">
              <div className="inline-flex items-center ">
                <p>Paypal Account Email</p>
                <input
                  type="text"
                  className="ml-5 w-1/3 rounded-2xl border border-gray-300 px-4 py-3 text-sm outline-none transition duration-500 ease-in hover:border   hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                  placeholder="PayPal Account Email"
                  value={creatorExtraInfo?.paypalEmail}
                  onChange={(e) =>
                    onChangeCreatorProfileExtra('paypalEmail', e.target.value)
                  }
                />
              </div>
              <br />
              <div className="grid md:inline-flex items-center ">
                <p>Minimum Project Requirement</p>
                <section className="md:ml-5 ml-0 md:mt-0 mt-5 flex w-fit place-self-center ">
                  <div className="relative">
                    <div
                      onClick={() => setDropMin(!dropMin)}
                      role="button"
                      tabIndex={0}
                      className="brand-color inline-flex w-[232px] cursor-pointer justify-between rounded-xl  px-[1rem] py-[0.5rem] text-center font-bold text-white duration-200 ease-in   hover:bg-sky-300 md:mt-4 lg:mt-0"
                    >
                      {!optionMinSelected ? (
                        <p className="self-center font-bold">Minimum Order</p>
                      ) : (
                        <div className="inline-flex">
                          <img
                            src="./img/dashboardFrameWhite.png"
                            alt=""
                            className="mr-5 h-6 w-6 self-center"
                          />{' '}
                          <p className="self-center font-bold">
                            {optionMinSelected} Photos
                          </p>
                        </div>
                      )}
                      <img
                        src="./img/downarrow.svg"
                        alt=""
                        className="h-7 w-8 invert"
                      />
                    </div>

                    {dropMin && (
                      <div
                        id="style-drop"
                        className="style-drop  scrollbar absolute z-10 mt-3 h-[240px] w-[232px]  rounded-xl bg-white transition duration-200 dark:bg-slate-800"
                      >
                        {options?.map((option: any, index: any) =>
                          option?.selected ? (
                            <div key={`options + ${index.toString()}`}>
                              <div
                                role="button"
                                tabIndex={0}
                                onClick={() => updateMinPhotos(option.value)}
                                className="inline-flex w-full cursor-pointer px-6 py-4 transition  duration-200 hover:bg-slate-100 dark:hover:bg-slate-700"
                              >
                                <img
                                  src="./img/dashboardFrame.png"
                                  alt=""
                                  className="h-6 w-6 "
                                />{' '}
                                <p className="ml-5 self-center  text-[1rem] text-sky-500">
                                  {option.label} Photos
                                </p>
                                <img
                                  src="./img/check2.png"
                                  alt=""
                                  className="ml-6 h-5 w-5 self-center"
                                />
                              </div>
                            </div>
                          ) : (
                            <div key={`options2 + ${index.toString()}`}>
                              <div
                                role="button"
                                tabIndex={0}
                                onClick={() => updateMinPhotos(option.value)}
                                className="inline-flex w-full cursor-pointer px-6 py-4 transition  duration-200 hover:bg-slate-100 dark:hover:bg-slate-700"
                              >
                                <img
                                  src="./img/dashboardFrame.png"
                                  alt=""
                                  className="h-6 w-6 self-center"
                                />{' '}
                                <p className="ml-5 self-center  text-[1rem] text-sky-500">
                                  {option.label} Photos
                                </p>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
          <input
            onChange={(e) => onChangeAvatar(e)}
            type="file"
            ref={fileUpload}
            accept="image/*"
            className="hidden"
          />

          <div
            role="button"
            tabIndex={0}
            onClick={clickFileUpload}
            className="grid w-[250px] md:ml-20  md:w-[23.43vw]"
          >
            <img
              src={`${BUCKET_IMAGES}avatar/${creatorUsername}/${creatorExtraInfo?.avatarUrl}`}
              alt="profilePicture"
              className="ml-[40px] inline h-60 w-60 self-center rounded-full object-cover transition-all duration-300 hover:cursor-pointer hover:border hover:shadow-md md:ml-0 md:h-[22vw] md:w-[25vw]"
            />
          </div>
        </section>

        <section className="mx-auto mt-20 grid w-full md:flex">
          <div className="w-1/2 border-gray-300 md:border-r-2">
            <h1 className="text-xl font-semibold">Shoot Location Access</h1>

            <div className="mt-10 grid grid-cols-1 gap-x-2 gap-y-4">
              {shotLocationSelected?.map((element: any, index: Number) => (
                <div
                  key={`shoot + ${index.toString()}`}
                  className="flex items-center"
                >
                  <input
                    type="checkbox"
                    id={`checkbox1-${index}`}
                    name={element.location}
                    checked={element.selected}
                    onChange={() =>
                      handleOnChangeShotLocation(element.location)
                    }
                    className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                  />
                  <label
                    className="ml-1 inline-flex place-self-center md:ml-[0.20vw] md:text-[0.83vw]"
                    htmlFor={`checkbox1-${index}`}
                  >
                    {element.location}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <hr className="m-auto my-10 block w-3/4 md:hidden" />
          <div className="w-full border-gray-300 md:border-r-2 md:pl-10">
            <h1 className="text-xl font-semibold ">Activity Access</h1>
            <div className="mt-10 grid gap-x-2 gap-y-4 md:w-8/12 md:grid-cols-2">
              {activityAccessSelected?.map((element: any, index: Number) => (
                <div
                  key={`act + ${index.toString()}`}
                  className="flex items-center"
                >
                  <input
                    type="checkbox"
                    id={`checkbox2-${index}`}
                    name={element.location}
                    checked={element.selected}
                    onChange={() => handleOnChangeActivity(element.location)}
                    className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                  />
                  <label
                    className="ml-1 inline-flex place-self-center md:ml-[0.20vw] md:text-[0.83vw]"
                    htmlFor={`checkbox2-${index}`}
                  >
                    {element.location}
                  </label>
                  <span
                    data-tip
                    data-for={element.location}
                    className="mx-2 cursor-pointer text-xs text-gray-400 dark:text-white"
                  >
                    <FontAwesomeIcon className="" icon={faQuestionCircle} />
                  </span>
                  <ReactTooltip
                    id={element.location}
                    type="info"
                    effect="solid"
                    className="tooltip"
                    place="right"
                    arrowColor="#54a3f5"
                  >
                    {element.tooltip}
                  </ReactTooltip>
                </div>
              ))}
            </div>
          </div>
          <hr className="m-auto my-10 block w-3/4 md:hidden" />
          <div className="w-full md:pl-10 ">
            <h1 className="text-xl font-semibold ">
              Specialization Categories
            </h1>
            <div className="mt-10 grid w-full gap-x-2 gap-y-4 md:w-fit md:grid-cols-4">
              {specializationCategoriesSelected?.map(
                (element: any, index: Number) => (
                  <div
                    key={`special + ${index.toString()}`}
                    className="flex items-center"
                  >
                    <input
                      type="checkbox"
                      id={`checkbox3-${index}`}
                      name={element.location}
                      checked={element.selected}
                      onChange={() =>
                        handleOnChangeSpecializationCategories(element.location)
                      }
                      className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                    />
                    <label
                      className="ml-1 inline-flex place-self-center md:ml-[0.20vw] md:text-[0.83vw]"
                      htmlFor={`checkbox3-${index}`}
                    >
                      {element.location}
                    </label>
                  </div>
                ),
              )}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
