import React, { useState, useRef, useEffect } from 'react';
import { Switch } from '@headlessui/react';

type Props = {
  userData: any;
  onChangeExtraCreator: any;
  onChangeAvatar: any;
  avatarPhoto: any;
  creatorInfoExtra: any;
  creatorfirstname: any;
  creatorlastname: any;
  creatoraddress: any;
  creatorphone: any;
  creatorcity: any;
  creatorzip: any;
  creatorstate: any;
  creatorbio: any;
  creatoravatar: any;
  paypalEmail: any;
};

export function UserInformationOnboarding(props: Props) {
  const {
    userData,
    onChangeExtraCreator,
    onChangeAvatar,
    avatarPhoto,
    creatorInfoExtra,
    creatorfirstname,
    creatorlastname,
    creatoraddress,
    creatorphone,
    creatorcity,
    creatorzip,
    creatorstate,
    creatorbio,
    creatoravatar,
    paypalEmail,
  } = props;

  const [contactViaPhone, setContactViaPhone] = useState(true);

  const handleContactViaPhone = () => {
    setContactViaPhone((value: boolean) => !value);
  };

  useEffect(() => {
    onChangeExtraCreator('contactViaPhone', contactViaPhone);
  }, [contactViaPhone]);

  const fileUpload = useRef<HTMLInputElement>(null);
  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  return (
    <div className="text-gray-600 dark:text-white">
      <div className="mb-10 flex w-full place-content-between">
        <h1 className="text-2xl font-semibold"> User Information </h1>
        <h1 className="mr-[6vw] hidden text-2xl font-semibold md:block">
          Profile Photo
        </h1>
      </div>

      <div className="m-auto grid w-full gap-5 md:flex md:gap-0">
        <section className="grid w-full gap-5 md:gap-0">
          <div className="grid w-full md:grid-cols-2 md:gap-5">
            <section className="grid gap-5">
              <input
                type="text"
                className="w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border   hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                ref={creatorfirstname}
                placeholder="First Name"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={1}
                value={creatorInfoExtra?.first_name}
                onChange={(e) =>
                  onChangeExtraCreator('first_name', e.target.value)
                }
              />
              <input
                type="text"
                className="w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border   hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                ref={creatoraddress}
                placeholder="Address"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={3}
                value={creatorInfoExtra?.address}
                onChange={(e) =>
                  onChangeExtraCreator('address', e.target.value)
                }
              />
              <div className="flex gap-5">
                <input
                  type="text"
                  className="w-11/12 rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white md:w-full"
                  ref={creatorcity}
                  placeholder="City"
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex={5}
                  value={creatorInfoExtra?.city}
                  onChange={(e) => onChangeExtraCreator('city', e.target.value)}
                />
                <input
                  type="text"
                  className="w-11/12 rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white md:w-32"
                  ref={creatorstate}
                  placeholder="State"
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex={6}
                  value={creatorInfoExtra?.state}
                  onChange={(e) =>
                    onChangeExtraCreator('state', e.target.value)
                  }
                />
                <input
                  type="text"
                  className="w-11/12 rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white md:w-full"
                  ref={creatorzip}
                  placeholder="Zip Code"
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex={7}
                  value={creatorInfoExtra?.zipCode}
                  onChange={(e) =>
                    onChangeExtraCreator('zipCode', e.target.value)
                  }
                />
              </div>
            </section>

            <section className="">
              <div className="mt-5 grid gap-5 md:mt-0">
                <input
                  type="text"
                  className="w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border   hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                  ref={creatorlastname}
                  placeholder="Last Name"
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex={2}
                  value={creatorInfoExtra?.last_name}
                  onChange={(e) =>
                    onChangeExtraCreator('last_name', e.target.value)
                  }
                />

                <div className="inline-flex gap-4">
                  <input
                    type="text"
                    className="w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border   hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                    ref={creatorphone}
                    placeholder="Phone"
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={4}
                    value={creatorInfoExtra?.phone}
                    onChange={(e) =>
                      onChangeExtraCreator('phone', e.target.value)
                    }
                  />

                  <input
                    type="text"
                    className="w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border   hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                    ref={paypalEmail}
                    placeholder="PayPal Account Email"
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={4}
                    value={creatorInfoExtra?.paypalEmail}
                    onChange={(e) =>
                      onChangeExtraCreator('paypalEmail', e.target.value)
                    }
                  />
                </div>
              </div>
              <div
                className="mt-6 flex gap-5"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={7}
                role="button"
              >
                <Switch
                  checked={contactViaPhone}
                  onChange={handleContactViaPhone}
                  className={`${contactViaPhone ? 'bg-sky-300' : 'bg-gray-300'}
                        duration-400 relative mr-1
                        inline-flex h-[1.8125rem] w-[4.0625rem]
                        shrink-0 cursor-pointer
                        place-self-center rounded-full
                        border-2 border-transparent transition-colors
                        ease-in-out focus:outline-none focus-visible:ring-2
                        focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span
                    aria-hidden="true"
                    className={`${contactViaPhone ? 'translate-x-9' : 'translate-x-0'
                      }
                        duration-400 pointer-events-none inline-block h-[1.5625rem]
                        w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out
                        dark:bg-slate-800`}
                  />
                </Switch>
                <div className="flex">
                  <p className="text-gray-300 dark:text-white">Yes</p>
                  <p className="ml-5 text-xs md:text-base">
                    Can Client Contact You Directly via Phone?
                  </p>
                </div>
              </div>
            </section>
          </div>
        </section>

        <h1 className="mr-[105px] block text-2xl font-semibold md:hidden">
          {' '}
          Profile Photo{' '}
        </h1>
        <input
          onChange={(e) => onChangeAvatar(e)}
          type="file"
          ref={fileUpload}
          accept="image/*"
          className="hidden"
        />

        <div className="mx-auto flex place-content-center md:ml-5 md:w-2/6">
          <div
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex={8}
            onClick={clickFileUpload}
            role="button"
            ref={creatoravatar}
            className="float-right flex h-60 w-60 place-content-end content-end rounded-full border-[0.125rem] border-dashed border-gray-300 bg-white text-right hover:cursor-pointer  hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800 dark:hover:bg-slate-500"
          >
            {avatarPhoto ? (
              <img
                src={URL.createObjectURL(avatarPhoto)}
                alt=""
                className="h-60 w-60 rounded-full bg-white  object-cover text-right hover:cursor-pointer  hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800 dark:hover:bg-slate-500"
              />
            ) : (
              <div className="m-auto content-center justify-center p-10 text-center align-middle">
                <img
                  src="./img/upload.png"
                  alt=""
                  className="inline h-9 w-9 self-center"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <textarea
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={9}
        onChange={(e) => onChangeExtraCreator('bio', e.target.value)}
        value={creatorInfoExtra?.bio}
        ref={creatorbio}
        rows={3}
        maxLength={2000}
        className="mt-5 min-h-[250px] w-full resize-none overflow-y-auto rounded-xl border border-gray-300 p-4 transition duration-500  ease-in focus:border-sky-500 focus:outline-none focus:ring-sky-500  dark:bg-slate-400 dark:placeholder:text-white"
        placeholder="Give brands an overview about yourself and your work"
      />
    </div>
  );
}
