import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { Modal } from 'components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {
  USERS_IMAGES_URL,
  SERVER_URL,
  BUCKET_IMAGES,
} from '../../common/CONFIG';
import './style.scss';

const zip = require('jszip')();

type Props = {
  lastProject: any;
  featuredCreators: any;
  lastProjectPhotosDownload: any;
};

export function AddNewProject(props: Props) {
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const { lastProject, featuredCreators, lastProjectPhotosDownload } = props;

  const [specializationFeatured, setSpecializationFeatured] = useState<any>('');
  const [openModal, setOpenModal] = useState(false);
  const [currentCreator, setCurrentCreator] = useState(['']);
  const [isBrandFav, setIsBrandFav] = useState(false);
  const [hideExtraButtons, setHideExtraButtons] = useState(false);

  function handleShowCreatorModal(creator: any) {
    setCurrentCreator(creator);
    setOpenModal(true);
  }

  useEffect(() => {
    if (featuredCreators) {
      setIsBrandFav(featuredCreators?.isBrandFav);
    }
  }, []);

  useEffect(() => {
    if (featuredCreators?.creatorExtraInfo?.specializationCategories) {
      const tempFav1 =
        featuredCreators?.creatorExtraInfo?.specializationCategories;
      let temp = '';
      const tempFav2 = JSON.parse(tempFav1);

      tempFav2.forEach((element: any) => {
        temp += `${element} `;
      });

      setSpecializationFeatured(temp);
      setIsBrandFav(featuredCreators?.isBrandFav);
    }
  }, [featuredCreators]);

  async function addCreatorToBrandsFav(userCreatorId: any) {
    setIsBrandFav(!isBrandFav);

    const route = `${SERVER_URL}addCreatorToBrandsFav/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        addCreatorToBrandsFav: userCreatorId,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {})
      .catch(() => {});
  }

  async function removeFavCreator(creatorId: number) {
    const route = `${SERVER_URL}deleteCreatorFromBrandsFav/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        deleteCreatorFromBrandsFav: creatorId,
      })
      .then((res) => {
        if (res.status === 201) {
          setIsBrandFav(!isBrandFav);
        }
        return res.data;
      })
      .catch(() => {});
  }
  const [currentInspirations, setCurrentInspirations] = useState<any>([]);

  async function addInspiration(photoId: number) {
    const temp: any = [...currentInspirations];

    temp.push(photoId);

    const route = `${SERVER_URL}addCreatorPhotoAsInspiration/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        photo: photoId,
        brand: currentUser.id,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setCurrentInspirations(temp);
      })
      .catch(() => {});
  }

  async function deleteInspiration(photoId: number) {
    const temp: any = [...currentInspirations];
    const tempInspi: any = [];

    temp.forEach((element: any) => {
      if (element !== photoId) {
        tempInspi.push(element);
      }
    });

    const route = `${SERVER_URL}deleteCreatorPhotoAsInspiration/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        photo: photoId,
        brand: currentUser.id,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setCurrentInspirations(tempInspi);
      })
      .catch(() => {});
  }
  const [inspirations, setInspirations] = useState([]);
  useEffect(() => {
    getBrandsInspirations();
  }, []);
  async function getBrandsInspirations() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getBrandsInspirationPhotos/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setInspirations(res.data);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    setCurrentInspirations(inspirations);
  }, [inspirations]);

  const [forRefresh, setForRefresh] = useState<any>();

  const navigate = useNavigate();

  async function duplicateProject(projectId: any) {
    const route = `${SERVER_URL}duplicateProject/${projectId}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route)
      .then((resp) => {
        if (resp.status === 201) {
          navigate(`/brief?edit=${resp.data}`);
        }
        return resp.data;
      })
      .catch(() => {});
  }

  const downloadPurchased = () => {
    const filename = `${lastProject.project.name}-project`;
    const urls = [];

    for (let i = 0; i < lastProjectPhotosDownload.length; i += 1) {
      const img = `${BUCKET_IMAGES}original/${lastProject.project.projectId}/${lastProjectPhotosDownload[i]}`;

      urls.push(img);
    }

    const folder = zip.folder('images');
    urls.forEach((url: any) => {
      const blobPromise = fetch(url)
        .then((response) => {
          // eslint-disable-line
          if (response.status !== 200 && response.status !== 0) {
            return Promise.reject(new Error(response.statusText)); // eslint-disable-line
          }
          return Promise.resolve(response.blob()); // eslint-disable-line
        })
        .catch((err) => console.log('Error while getting images', err));
      const name = url.substring(url.lastIndexOf('/'));
      folder.file(name, blobPromise);
    });

    zip
      .generateAsync({ type: 'blob' })
      .then((content: any) => {
        // eslint-disable-line
        saveAs(content, `${filename}.zip`);
        return true;
      })
      .catch((err: any) => console.log('Error while generating zip file', err));
  };

  return (
    <>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={currentCreator}
        isBrandFav={isBrandFav}
        setIsBrandFav={setIsBrandFav}
        hideExtraButtons={hideExtraButtons}
        currentInspirations={currentInspirations}
        setCurrentInspirations={setCurrentInspirations}
        setForRefresh={setForRefresh}
      />
      <div className="pt-8 md:overflow-x-hidden">
        <div className="dashboard-background mx-auto min-h-[65.625rem] w-10/12 rounded-2xl bg-white align-middle dark:bg-slate-800 dark:bg-blend-multiply">
          <h1 className="pl-10 pt-10 text-left text-3xl font-semibold text-gray-600 dark:text-white">
            Dashboard
          </h1>
          <section className="md:mx-5 my-0 grid items-center justify-around md:flex">
            {/* Left radial interactive "new project"  */}
            <Link to="/brief">
              <section className="mx-auto items-center text-center align-middle md:mr-4 mt-10 md:mt-0 mr-10">
                <figure className="z-0 md:m-5 h-[20rem] w-[20rem] items-center justify-center rounded-full bg-white text-center align-middle shadow-[0_1.25rem_3.75rem_0.625rem_rgba(63,127,191,0.12)] dark:bg-slate-700 md:h-[30rem] md:w-[30rem]">
                  <figure className=" relative left-12 top-12 z-10 h-[14rem] w-[14rem] items-center justify-center rounded-full bg-white text-center align-middle shadow-[0_1.25rem_3.75rem_0.625rem_rgba(45,51,46,0.05)] dark:bg-slate-600 md:h-[24rem] md:w-[24rem]">
                    <figure className=" relative left-12 top-12  z-20 grid h-[8rem] w-[8rem] items-center justify-center rounded-full bg-white text-center align-middle shadow-[0_1.25rem_3.75rem_0.625rem_rgba(45,51,46,0.05)] duration-200 ease-in hover:left-[2.5rem] hover:top-[2.5rem] hover:h-[9rem] hover:w-[9rem] hover:bg-sky-50 hover:shadow-[0_1.25rem_3.75rem_0.625rem_rgba(45,51,46,0.15)] dark:bg-slate-500 dark:hover:bg-[#babac067]  md:flex md:h-[18rem] md:w-[18rem] md:hover:left-[2.5rem] md:hover:top-[2.375rem]  md:hover:h-[19rem] md:hover:w-[19rem]">
                      <div className="z-30 -mt-[1.875rem] shrink-0 align-middle leading-tight hover:cursor-pointer">
                        <h1 className="text-[4.25rem] font-thin text-sky-500  md:text-[6.25rem]">
                          +
                        </h1>
                        <h1 className="text-[0.625rem] font-normal text-sky-500 md:text-lg">
                          Add New Project
                        </h1>
                      </div>
                    </figure>
                  </figure>
                </figure>
              </section>
            </Link>

            {lastProject?.project && (
              <section className="relative w-[350px] md:w-min ">
                <div className="relative z-20 min-h-[41.25rem] w-[350px] rounded-3xl bg-white shadow-[0_0.3125rem_0.9375rem_0.3125rem_rgba(63,127,191,0.15)]  dark:bg-slate-800 md:min-h-[51.875rem] md:w-[46.25rem]">
                  <figure className="absolute right-0 top-20 z-10 hidden h-28 w-8 bg-slate-400 md:block" />
                  <Link to={`/project?id=${lastProject?.project?.projectId}`}>
                    <img
                      src={`${USERS_IMAGES_URL}${lastProject?.project?.projectImage}`}
                      alt=""
                      className="absolute z-30 mx-6 mt-6 w-[300px] rounded-2xl object-cover md:h-[31.25rem] md:w-[43.4375rem]"
                    />
                  </Link>
                  <section className="bottom-0 grid w-[21.875rem] items-center justify-between pt-[17.5rem] md:absolute md:bottom-48 md:top-[31.5625rem] md:flex md:w-full md:pt-0">
                    <h1 className="ml-8 text-left text-3xl font-semibold text-gray-600 dark:text-white md:py-10">
                      {lastProject?.project?.name}
                    </h1>
                    {/* <div className="ml-[2rem] mt-3 flex md:ml-0 md:mt-0">
                  <img
                    alt="logo"
                    src="./img/dashboardFrame.png"
                    className="h-6 w-6"
                  />
                  <p className="ml-2 mr-8 text-sky-400">8 Photos Purchased</p>
                </div> */}
                  </section>

                  <section className="static top-[40.625rem] m-8 md:m-auto mt-10 grid w-72 content-center items-center md:absolute md:bottom-48 md:mt-0 md:flex md:w-full md:justify-between">
                    <div className="flex place-items-center items-center">
                      {/* <img
                    alt="photographer"
                    src="https://images.assetsdelivery.com/compings_v2/fxquadro/fxquadro1603/fxquadro160300732.jpg"
                    className="h-20 w-20 rounded-full md:ml-8 md:h-12 md:w-12"
                  />
                  <h1 className="ml-3 text-xl font-normal text-gray-600 dark:text-white">
                    Rafael Rodrigues
                  </h1> */}
                    </div>
                    <div className="grid w-full items-center justify-between md:flex">
                      {/* <div className="absolute top-[27.8125rem] left-[9.0625rem] flex md:relative md:top-0 md:left-0">
                    <img
                      alt="frame"
                      src="./img/ship.png"
                      className="h-4 w-3 self-center md:h-6 md:w-5"
                    />
                    <p className="ml-2 mr-8 place-self-center text-gray-400 dark:text-white">
                      Shipping
                    </p>
                  </div> */}
                      <div className="ml-[2rem] mt-3 flex items-center md:ml-8 md:mt-0	">
                        <img
                          alt="logo"
                          src="./img/dashboardFrame.png"
                          className="h-8 w-8"
                        />
                        <p className="ml-2 mr-8 text-lg text-sky-400">
                          {lastProject?.project?.qtyPurchased} Photos Purchased
                        </p>
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => downloadPurchased()}
                        className="download-icon mt-3 rounded-3xl bg-sky-500 py-3 text-center text-white shadow-lg shadow-sky-500/50 duration-200 ease-in hover:cursor-pointer hover:bg-sky-400 dark:hover:bg-sky-300 md:mr-8 md:mt-0 md:pl-10 md:pr-6"
                      >
                        Download
                      </div>
                    </div>
                  </section>

                  <div className="absolute left-[3.75rem] top-[35rem] m-auto md:left-[8.125rem] md:top-[45rem]">
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        duplicateProject(lastProject?.project?.projectId)
                      }
                    >
                      <div className="md:m-0 -m-[31px] rounded-2xl bg-sky-100 p-6 px-[4.6875rem] text-center font-semibold tracking-wide text-sky-600 duration-200 ease-in hover:bg-sky-300 focus:outline-none dark:bg-slate-600 dark:text-sky-500  dark:hover:bg-slate-500 md:w-[29.375rem]">
                        Duplicate Project
                      </div>
                    </div>
                  </div>
                </div>
                <figure className="absolute right-[8.125rem] top-[9.375rem] z-0 hidden min-h-[44.375rem] w-[40rem] rounded-3xl bg-white shadow-[0_1.25rem_1.875rem_0.625rem_rgba(63,127,191,0.15)] dark:bg-slate-800 md:block" />
              </section>
            )}

            {!lastProject?.project && featuredCreators && (
              <section className="relative w-[300px] md:w-min ">
                <div className="relative z-20 min-h-[41.25rem] w-[300px] rounded-3xl bg-white shadow-[0_0.3125rem_0.9375rem_0.3125rem_rgba(63,127,191,0.15)]  dark:bg-slate-800 md:min-h-[51.875rem] md:w-[46.25rem]">
                  <div className="grid grid-cols-3 gap-5 p-5	">
                    {featuredCreators?.creatorPhotos?.map(
                      (element: any, index: any) =>
                        index < 6 && (
                          <div
                            key={`feat + ${index.toString()}`}
                            className="relative h-[220px] w-full rounded-2xl hover:opacity-90"
                          >
                            <div className="absolute right-[4%]  z-20 grid place-content-between">
                              <div />
                              {currentInspirations.includes(element.id) ? (
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => deleteInspiration(element.id)}
                                >
                                  <img
                                    src="./img/starFillCircle.png"
                                    alt=""
                                    className="mt-2 h-10 w-10 hover:cursor-pointer hover:opacity-80"
                                  />
                                </div>
                              ) : (
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => addInspiration(element.id)}
                                >
                                  <img
                                    src="./img/starEmptyCircle.png"
                                    alt=""
                                    className="mt-2 h-10 w-10 hover:cursor-pointer hover:opacity-80"
                                  />
                                </div>
                              )}
                            </div>

                            <img
                              src={`${BUCKET_IMAGES}portfolio/thumbnails/${featuredCreators?.user?.username}/${element?.thumbnailUrl}`}
                              alt=""
                              className="z-10 h-full w-full rounded-2xl object-cover hover:opacity-90"
                            />
                          </div>
                        ),
                    )}
                  </div>

                  <section className="bottom-0 grid w-[21.875rem] items-center justify-between md:absolute md:bottom-48 md:top-[25.5rem] md:flex md:w-full">
                    <h1 className="ml-8 text-left text-base font-bold text-gray-600 dark:text-white ">
                      Photographer
                    </h1>
                    <div className="ml-[2rem] mt-3 flex md:ml-0 md:mt-0  ">
                      <img
                        alt="logo"
                        src="./img/arrows.png"
                        className="mr-2 h-3 w-3 self-center opacity-70 dark:opacity-100 dark:invert"
                      />
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => handleShowCreatorModal(featuredCreators)}
                      >
                        <p className="ml-2 mr-8 self-center text-gray-800 hover:text-sky-500 dark:text-white">
                          View More
                        </p>
                      </div>
                    </div>
                  </section>

                  <section className="static top-[37.625rem] m-auto mt-10 grid w-72 content-center items-center md:absolute md:bottom-48 md:mt-0 md:flex md:w-full md:justify-between">
                    <div className="flex place-items-center items-center">
                      <img
                        alt="photographer"
                        src={`${BUCKET_IMAGES}avatar/${featuredCreators?.user?.username}/${featuredCreators?.creatorExtraInfo?.avatarUrl}`}
                        className="h-20 w-20 rounded-full md:ml-8 md:h-28 md:w-28"
                      />
                      <div>
                        <h1 className="ml-5 text-xl font-bold text-gray-600 dark:text-white">
                          {featuredCreators?.creatorExtraInfo?.nickname}
                        </h1>
                        <h1 className="ml-5  text-gray-600 dark:text-white">
                          Activities: {specializationFeatured}
                        </h1>

                        <div className="ml-5 inline-flex">
                          <img
                            alt="frame"
                            src="./img/shipBlue.png"
                            className="h-3 w-3 self-center md:h-3 md:w-[0.65rem]"
                          />
                          <p className="ml-2 mr-8 place-self-center text-xs text-gray-400 dark:text-white">
                            Located: {featuredCreators?.creatorExtraInfo?.city}
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="absolute top-[35rem] m-auto inline-flex w-full content-between justify-between px-8 md:top-[45rem]">
                    {isBrandFav ? (
                      <div
                        onClick={() =>
                          removeFavCreator(featuredCreators?.user?.id)
                        }
                        role="button"
                        tabIndex={0}
                        className="z-0 inline-flex rounded-full  border-2 border-sky-500  bg-sky-500 px-12 py-4 text-center text-xl text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
                      >
                        <img
                          src="./img/bookmarkFilledWhite.png"
                          alt=""
                          className="mr-3 h-6 w-5 self-center"
                        />
                        Added To Favorites
                      </div>
                    ) : (
                      <div
                        onClick={() =>
                          addCreatorToBrandsFav(featuredCreators?.user?.id)
                        }
                        role="button"
                        tabIndex={0}
                        className="z-0  inline-flex rounded-full  border-2 border-sky-500  bg-sky-500 px-12 py-4 text-center text-xl text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
                      >
                        <img
                          src="./img/bookmarkFavWhite.png"
                          alt=""
                          className="mr-3 h-6 w-5 self-center"
                        />
                        Add To Favorites
                      </div>
                    )}

                    <Link to="/photographer">
                      <div className="z-0  self-center rounded-full border-2 border-gray-400 px-10 py-4 text-xl text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:text-white dark:hover:text-sky-400">
                        Explore Photographers
                      </div>
                    </Link>
                  </div>
                </div>
                <figure className="absolute right-[8.125rem] top-[9.375rem] z-0 hidden min-h-[44.375rem] w-[40rem] rounded-3xl bg-white shadow-[0_1.25rem_1.875rem_0.625rem_rgba(63,127,191,0.15)] dark:bg-slate-800 md:block" />
              </section>
            )}
          </section>
        </div>
      </div>
    </>
  );
}
