import React, { SyntheticEvent, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { Modal } from 'components';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import axios from 'axios';
import './style.scss';
import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';

type Props = {
  data: any;
  creatorIndex: any;
  search: any;
  setRefreshFavCreatorState: any;
  inspirations: any;
};

export function PhotographerCard(props: Props) {
  const {
    data,
    creatorIndex,
    search,
    setRefreshFavCreatorState,
    inspirations,
  } = props;

  const [loaded, setLoaded] = useState(false);

  const [isBrandFav, setIsBrandFav] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [specialization, setSpecialization] = useState([]);
  const [photos, setPhotos] = useState<any>([]);

  useEffect(() => {
    if (data?.creatorPhotos) {
      setPhotos(data?.creatorPhotos);
    }

    const temp = data?.isBrandFav;
    setIsBrandFav(temp);
  }, [data?.creatorPhotos]);

  useEffect(() => {
    if (data?.isBrandFav) {
      setIsBrandFav(data?.isBrandFav);
    }
  }, [data?.isBrandFav]);

  useEffect(() => {
    if (data?.isBrandFav) {
      setIsBrandFav(data?.isBrandFav);
    }
  }, []);

  const [currentInspirations, setCurrentInspirations] = useState<any>([]);

  useEffect(() => {
    setCurrentInspirations(inspirations);
  }, [inspirations]);

  useEffect(() => {
    if (data?.creatorExtraInfo?.specializationCategories) {
      setSpecialization(
        JSON.parse(data?.creatorExtraInfo?.specializationCategories),
      );
    }
  }, [data?.creatorExtraInfo?.specializationCategories]);

  function loadingImage(event: SyntheticEvent): void {
    setLoaded(true);
  }

  function setCurrentCreatorIndexFunc(index: number) {
    setCurrentCreatorIndex(index);

    setOpenModal(true);
  }

  async function addCreatorToBrandsFav(userCreatorId: any) {
    setIsBrandFav(!isBrandFav);

    setRefreshFavCreatorState(creatorIndex);

    const route = `${SERVER_URL}addCreatorToBrandsFav/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        addCreatorToBrandsFav: userCreatorId,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {})
      .catch(() => {});
  }

  async function removeFavCreator(creatorId: number) {
    setIsBrandFav(!isBrandFav);

    setRefreshFavCreatorState(creatorIndex);

    const route = `${SERVER_URL}deleteCreatorFromBrandsFav/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        deleteCreatorFromBrandsFav: creatorId,
      })
      .then((res) => {
        if (res.status === 201) {
          setRefreshFavCreatorState(-1);
        }
        return res.data;
      })
      .catch(() => {});
  }

  const [currentCreatorIndex, setCurrentCreatorIndex] = useState(-1);
  const [currentCreator, setCurrentCreator] = useState(['']);

  useEffect(() => {
    if (currentCreatorIndex !== -1) {
      const temp = [...search];
      const tempCurrent = temp[currentCreatorIndex];
      setCurrentCreator(tempCurrent);
      // setOpenModal(true);
    }
  }, [currentCreatorIndex, search]);

  const [hideExtraButtons, setHideExtraButtons] = useState(true);

  async function addInspiration(photoId: number) {
    const temp: any = [...currentInspirations];

    temp.push(photoId);

    const route = `${SERVER_URL}addCreatorPhotoAsInspiration/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        photo: photoId,
        brand: currentUser.id,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setCurrentInspirations(temp);
      })
      .catch(() => {});
  }

  async function deleteInspiration(photoId: number) {
    const temp: any = [...currentInspirations];
    const tempInspi: any = [];

    temp.forEach((element: any) => {
      if (element !== photoId) {
        tempInspi.push(element);
      }
    });

    const route = `${SERVER_URL}deleteCreatorPhotoAsInspiration/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        photo: photoId,
        brand: currentUser.id,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setCurrentInspirations(tempInspi);
      })
      .catch(() => {});
  }

  const [forRefresh, setForRefresh] = useState<any>();

  return (
    <>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={currentCreator}
        isBrandFav={isBrandFav}
        setIsBrandFav={setIsBrandFav}
        hideExtraButtons={hideExtraButtons}
        currentInspirations={currentInspirations}
        setCurrentInspirations={setCurrentInspirations}
        setForRefresh={setForRefresh}
      />

      <div className="mx-auto my-2 mt-10 h-fit w-[21.875rem] rounded-2xl bg-white leading-[1.8vh] dark:bg-slate-800 dark:bg-blend-multiply md:mx-0 md:h-fit md:w-[63.33vw] md:p-[1vw]">
        <div className="flex flex-col-reverse justify-items-center dark:bg-blend-multiply md:min-h-[31.875rem] md:flex-row">
          <section className="mt-3 grid grid-cols-2 justify-items-center gap-3 md:mx-2 md:grid-cols-3 md:gap-5">
            {photos.map(
              (element: any, index: any) =>
                index < 6 && (
                  <div className="relative mt-5 md:mt-0" key={element.id}>
                    {currentInspirations.includes(element.id) ? (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => deleteInspiration(element.id)}
                      >
                        <img
                          data-tip
                          data-for="starToolTip"
                          src="./img/starFillCircle.png"
                          alt=""
                          className="absolute right-[0.5rem] top-2 h-5 w-5 opacity-90 hover:cursor-pointer hover:opacity-100 md:right-2 md:h-8  md:w-8"
                        />
                      </div>
                    ) : (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => addInspiration(element.id)}
                      >
                        <img
                          data-tip
                          data-for="starToolTip"
                          src="./img/star.png"
                          alt=""
                          className="absolute right-[0.5rem] top-2 h-5 w-5 opacity-90 hover:cursor-pointer hover:opacity-100 md:right-2 md:h-8  md:w-8"
                        />
                      </div>
                    )}

                    <img
                      style={{ display: loaded ? 'block' : 'none' }}
                      src={`${BUCKET_IMAGES}portfolio/thumbnails/${data?.user.username}/${element?.thumbnailUrl}`}
                      onLoad={(event) => loadingImage(event)}
                      alt=""
                      className="h-32 w-32 rounded-3xl object-cover md:h-[23.47vh] md:w-[11.45vw]"
                    />

                    {!loaded && (
                      <img
                        src="./img/loading.gif"
                        alt="Loading"
                        className=" h-32 w-32 rounded-3xl object-cover md:h-[23.47vh] md:w-[11.45vw]"
                      />
                    )}

                    <ReactTooltip
                      id="starToolTip"
                      type="info"
                      effect="solid"
                      className="tooltip"
                    >
                      Photos you like that match the feel, style...
                    </ReactTooltip>
                  </div>
                ),
            )}
          </section>

          <section className="relative mt-3 inline-block p-5 dark:bg-blend-multiply md:w-[26.66vw] md:p-0 ">
            <img
              src={`${BUCKET_IMAGES}avatar/${data?.user.username}/${data?.creatorExtraInfo?.avatarUrl}`}
              alt=""
              className="absolute left-[72px] z-20 inline-block h-[200px] w-[200px] rounded-full object-cover align-middle md:left-[8rem] md:h-[21.34vh] md:w-[200px] "
            />
            <div className="background-photographer-card z-10 m-auto h-[21.34vh] w-[200px] text-center dark:mix-blend-multiply md:mx-2 md:my-0 md:w-[23.43vw]" />

            <div className="mt-10 md:mx-6 md:mt-[2vh]">
              <h1 className="text-[2.5vh] font-bold text-gray-700 dark:text-white">
                {data?.creatorExtraInfo?.nickname}
              </h1>
              <p className="mt-6 text-gray-700 dark:text-white md:mt-[2vh]  md:text-[0.8vw] line-clamp-2">
                {data?.creatorExtraInfo?.bio}
              </p>

              <h1 className="mt-4 text-gray-700 dark:text-white md:text-[0.8vw]">
                Highlights & Specializations
              </h1>
              <ul className="mt-3 ml-5 text-gray-700 dark:text-white">
                {specialization?.map((element: any, index: number) => (
                  <li
                    className="list-disc text-gray-700 dark:text-white md:text-[0.8vw]"
                    key={`highlspec + ${index.toString()}`}
                  >
                    {element}
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
        <section className="mt-5 grid items-center justify-items-center md:flex md:justify-between">
          <div className="my-5 flex align-middle md:my-0">
            <img
              src="./img/arrows.png"
              alt=""
              className="h-3 w-3 self-center dark:invert md:ml-3"
            />
            <div
              onClick={() => setCurrentCreatorIndexFunc(creatorIndex)}
              role="button"
              tabIndex={0}
              className="ml-3 text-[1.92vh] text-gray-700 hover:cursor-pointer hover:text-sky-500 dark:text-white dark:hover:text-sky-500"
            >
              View More
            </div>
          </div>
          <div className="my-5 mr-3 flex align-middle md:my-0 md:mr-0">
            {isBrandFav ? (
              <div
                onClick={() => removeFavCreator(data?.user?.id)}
                role="button"
                tabIndex={0}
                className="mr-4	 inline-flex items-center self-center text-xs font-semibold text-gray-700 transition-all duration-200 hover:cursor-pointer hover:text-sky-500 dark:text-white dark:hover:text-sky-500 md:text-[0.85vw]"
              >
                Added to Favorites
                <img
                  src="./img/bookmarkFilled.png"
                  alt=""
                  className="mx-3 self-center md:h-[3.84vh] md:w-[1.25vw]"
                />
              </div>
            ) : (
              <div
                onClick={() => addCreatorToBrandsFav(data?.user?.id)}
                role="button"
                tabIndex={0}
                className="mr-4	 inline-flex items-center self-center text-xs font-semibold text-gray-700 transition-all duration-200 hover:cursor-pointer hover:text-sky-500 dark:text-white dark:hover:text-sky-500 md:text-[0.85vw]"
              >
                Add Photographer to Favorites
                <img
                  src="./img/bookmark.png"
                  alt=""
                  className="mx-3 self-center md:h-[3.84vh] md:w-[1.25vw]"
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}
